export default {
  common: {
    title: 'Sistema de Afiliados',
    logout: 'Sair',
    changePassword: 'Alterar Senha',
    login: 'Entrar',
    signup: 'Cadastrar-se',
    username: 'Nome de Usuário',
    loginnow: 'Entrar Agora',
    password: 'Senha',
    apply: 'Aplicar',
    verificationcode: 'Código de Verificação',
    verification_title:
      'Verificação de Segurança, por favor clique passo a passo:',
    verification_drag_title: 'Verificação de Segurança, arraste para verificar',
    verify_success: 'Verificação Bem-sucedida',
    refresh: 'Atualizar',
    submit: 'Enviar',
    register_affi: 'Registre-se Agora',
    back_login: 'Voltar ao Login',
    contact_us: 'Contate-nos',
    affiliateaccountcanonlycontainnumchar:
      'A conta de afiliado só pode conter sublinhados (_), números e letras.',
    confirmpassword: 'Confirme a senha.',
    affiliateaccount: 'Nome da Conta de Afiliado',
    forgetpass: 'Esqueceu a Senha.',
    zhuanshukefufuwu: 'Serviço de Atendimento ao Cliente Exclusivo',
    askus: 'Solicitar',
    download: 'Baixar',
    copy: 'Copiar',
    copied: 'Copiado',
    email: 'E-mail',
    qq: 'QQ',
    skype: 'Skype',
    paopao: 'Bubble',
    zalo: 'Zalo',
    telegram: 'Telegram',
  },
  depositStatus: {
    SUCCESS: 'Sucesso',
    SUPPLEMENT_SUCCESS: 'Suplemento Sucesso',
    CLOSED: 'Fechado',
    PENDING: 'Pendente',
  },
  withdrawStatus: {
    ALL: 'Todos',
    SUCCESS: 'Sucesso',
    FAIL: 'Falha',
    APPLY: 'Solicitando',
    STEP_1: 'Em Revisão',
    STEP_2: 'A Pagar',
    STEP_3: 'Pagamento em Andamento',
    AUTOPAY: 'Pagamento Automático',
    PENDING: 'Suspenso',
    REJECT: 'Rejeitado',
    SENDING: 'Enviando',
    WAITING_CALLBACK: 'Aguardando Retorno',
    PAYING: 'Pagando',
    WAITING_AUTO_PAY: 'Aguardando Pagamento Automático',
  },
  withdrawConfirmStatus: {
    0: 'Não Confirmado',
    1: 'Recebido',
  },
  questions: {
    mothersName: 'Qual é o nome da sua mãe?',
    mothersBirthday: 'Quando é o aniversário da sua mãe?',
    dadsName: 'Qual é o nome do seu pai?',
    dadsBirthday: 'Quando é o aniversário do seu pai?',
    spouseBirthday: 'Quando é o aniversário do seu cônjuge?',
    cityBorn: 'Em que cidade você nasceu?',
    highSchool: 'Em qual escola secundária você estudou?',
    elementarySchool: 'Qual era o nome da sua escola primária?',
    firstPetName: 'Qual era o nome do seu primeiro animal de estimação?',
    firstCar: 'Qual é a marca do seu primeiro carro?',
  },
  gameType: {
    SLOT: 'CAÇA-NÍQUEIS',
    LIVE: 'AO VIVO',
    FISH: 'PESCA',
    SPORT: 'ESPORTES',
    ESPORT: 'ESPORT',
    POKER: 'POKER',
    LOTTERY: 'LOTERIA',
    MINIGAME: 'MINIJOGOS',
  },
  result: {
    WIN: 'VITÓRIA',
    LOSS: 'DERROTA',
    DRAW: 'EMPATE',
  },
  betStatus: {
    UNSETTLED: 'Não Resolvido',
    SETTLED: 'Resolvido',
    CANCEL: 'Cancelado',
  },
  transferType: {
    DEPOSIT: 'Transferir do Saldo',
    COMMISSION: 'Transferir do Saldo de Comissão',
  },
  creditFlowType: {
    Withdraw: 'Saque',
    Transfer: 'Transferência',
    Dividen: 'Dividendo',
    Deposit: 'Depósito',
    Agent_Transfer: 'Transferência de Agente',
    Agent_Deposit: 'Depósito de Agente',
    COMMISSION_WALLET_WITHDRAWAL: 'Saque',
    COMMISSION_WALLET_AFFILIATE_DEPOSIT: 'Depósito de Afiliado',
    COMMISSION_WALLET_SETTLEMENT: 'Liquidação de Comissão',
    DEPOSIT_WALLET_DEPOSIT: 'Depósito',
    DEPOSIT_WALLET_AFFILIATE_DEPOSIT: 'Depósito de Afiliado',
    DEPOSIT_WALLET_AMOUNT_ADJUST: 'Ajuste Manual',
  },
  referralLink: {
    affiliateWebPlatformLink: 'Link de Afiliado para Plataforma PC',
    affiliateH5PlatformLink:
      'Link de Afiliado para Plataforma H5 (Recomendado)',
    affiliateWXShortLink: 'URL Anti-Bloqueio (Wechat)',
    affiliateQQShortLink: 'URL Anti-Bloqueio (QQ)',
    affiliateZALOShortLink: 'URL Anti-Bloqueio (Zalo)',
    affiliateLongLink: 'URL Normal',
    affiliateWXQRLink: 'QR Code Anti-Bloqueio (Wechat)',
    affiliateQQQRLink: 'QR Code Anti-Bloqueio (QQ)',
    affiliateZALOQRLink: 'QR Code Anti-Bloqueio (Zalo)',
    affiliateLongQRLink: 'QR Code de Link Normal',
    affiliateDownloadQRtoLocal: 'Baixar',
    affiliateScanMe: 'Escaneie e Veja no Celular',
    copiedWXShortUrl: 'URL Anti-Bloqueio do Wechat Copiada com Sucesso',
    copiedQQShortUrl: 'URL Anti-Bloqueio do QQ Copiada com Sucesso',
    copiedZALOShortUrl: 'URL Anti-Bloqueio do Zalo Copiada com Sucesso',
    copiedLink: 'Copiado com Sucesso',
    downloadQRCode: 'Baixar Código QR',
    affiliateShortLink: 'Domínio Curto',
  },
  commissionInfo: {
    commissionInfo: 'Informações de Comissão',
    affiliateCommissionInfo: 'Informações de Comissão de Afiliado',
    commissionCalculator: 'Calculadora de Comissão',
    monthlyTotalEffectiveTurnover: 'Lucro/Perda Mensal Total',
    pleaseInsertMonthlyTotalEffectiveTurnover:
      'Por favor, insira o Lucro/Perda Mensal Total',
    monthlySettlement: 'Liquidação Mensal',
    pleaseInsertMonthlySettlement: 'Por favor, insira a Liquidação Mensal',
    monthlyDividen: 'Bônus Mensal',
    pleaseInsertMonthlyDividen: 'Por favor, insira o Bônus Mensal',
    platformFee: 'Taxa de Plataforma',
    monthlyTotalDeposit: 'Depósito Mensal Total de Membros',
    pleaseInsertMonthlyTotalDeposit:
      'Por favor, insira o Depósito Mensal Total de Membros',
    monthlyTotalWithdrawal: 'Saque Mensal Total de Membros',
    pleaseInsertMonthlyTotalWithdrawal:
      'Por favor, insira o Saque Mensal de Membros',
    totalCommissionAbleClaim: 'Comissão Total Mensal a Receber',
    calculate: 'Calcular',
    commissionCalculatorNote:
      'Nota: O simulador calcula com base na média de reembolso e estimativas de bônus, e a seleção do local é padronizada para a situação em que todos os membros participam do jogo no mesmo local. Os resultados são apenas para referência.',
    commissionRateInfo: 'Informações da Taxa de Comissão',
    commissionRateDescription:
      'A taxa de comissão de afiliados é determinada pelo lucro/perda mensal e o número de membros ativos. Quando ambas as condições forem atendidas, o afiliado receberá a taxa de comissão correspondente.',
    activeMemberRequirement:
      'Número de membros ativos: Depósito do mês >= 100 ou aposta válida >= 500;',
    level: 'Nível',
    companyMonthlyProfit: 'Lucro Mensal da Empresa',
    activeNumberRequirement: 'Requisito Mínimo de Membros Ativos/Novos',
    commissionRate: 'Taxa de Comissão',
    commissionCalculateFormula: 'Fórmula de Cálculo da Comissão',
    commissionFormula:
      'Comissão = Lucro Líquido Após Correção * Taxa de Comissão + Ajuste de Comissão + Suplemento de Comissão;',
    correctionNetProfitFormula:
      'Lucro Líquido Após Correção = Lucro Líquido Mensal + Saldo do Mês Anterior;',
    monthlyNetProfitFormula:
      'Lucro Líquido Mensal = Lucro/Perda Mensal Total - Bônus - Reembolso - Taxa de Plataforma - Ajuste de Conta + Suplemento de Lucro/Perda - Taxa de Processamento de Depósito/Saque;',
    bonusFormula:
      'Bônus = Valor Total de Bônus recebido pela linha de afiliados no mês corrente;',
    rebateFormula:
      'Reembolso = Valor Total de Reembolso recebido pela linha de afiliados no mês corrente;',
    platformFeeFormula:
      'Taxa de Plataforma = Lucro/Perda Total de cada Plataforma * Taxa de Plataforma, mas apenas quando houver lucro no mês, a plataforma cobrará a taxa;',
    adjustmentFormula:
      'Ajuste de Conta = Quando o rollover do membro for anormal, o administrador do site fará ajustes na conta do membro;',
    supplementWinLossFormula:
      'Suplemento de Lucro/Perda = Dados em Tempo Real do Lucro Líquido do Mês Anterior - Lucro Líquido da comissão distribuída no mês anterior;',
    depositFeeFormula:
      'Taxa de Depósito = Valor de Depósito da Linha de Afiliados * Taxa de Depósito + Valor de Depósito do Afiliado * Taxa de Depósito;',
    withdrawalFeeFormula:
      'Taxa de Saque = Valor de Saque da Linha de Afiliados * Taxa de Saque;',
    commissionAdjustmentNote:
      'Suplemento de Comissão: Após a liberação da comissão do mês, será feito um suplemento de comissão com base nas atividades relevantes nas quais o afiliado participou.',
    commissionNote:
      'Nota: Lembre-se de que qualquer pessoa que usar métodos desonestos para fraudar comissões terá sua conta permanentemente congelada e a parceria encerrada. Todas as comissões não serão pagas.',
  },
  sortType: {
    ASC: 'ASC',
    DESC: 'DESC',
  },
  rebateStatus: {
    PENDING: 'PENDENTE',
    IN_PROGRESS: 'EM PROGRESSO',
    DISTRIBUTED: 'DISTRIBUÍDO',
    CANCEL: 'CANCELADO',
  },
  fields: {
    account: 'Conta',
    accountInfo: 'Informações da Conta',
    activeMember: 'Membro Ativo',
    activePlayer: 'Jogador Ativo',
    activeUsers: 'Usuários Ativos',
    add: 'Adicionar',
    addBankCard: 'Adicionar Cartão Bancário',
    addVirtualCard: 'Adicionar Cartão Virtual',
    adjust: 'Ajustar',
    adjustAmount: 'Ajustar Valor',
    adjustment: 'Ajuste',
    adjustReason: 'Motivo do Ajuste',
    adjustType: 'Tipo de Ajuste',
    affiliate: 'Afiliado',
    affiliateAccount: 'Conta de Afiliado',
    affiliateCode: 'Código de Afiliado',
    affiliateDeposit: 'Depósito',
    affiliateInfo: 'Informações do Afiliado',
    affiliateLevel: 'Nível de Afiliado',
    affiliateStatus: 'Status do Afiliado',
    affiliateWithdraw: 'Retirada Bancária',
    affiliateWithdrawRecord: 'Registro de Retirada Bancária',
    amount: 'Valor',
    amountOfFirstDeposit: 'Valor do Primeiro Depósito',
    answerOne: 'Resposta Um',
    answerTwo: 'Resposta Dois',
    answerThree: 'Resposta Três',
    answerSecurityQuestion: 'Responder Todas as Perguntas de Segurança',
    appLink: 'Link de Download do Aplicativo',
    balance: 'Saldo',
    bank: 'Banco',
    bankCard: 'Cartão Bancário',
    bankId: 'ID do Banco',
    bankName: 'Nome do Banco',
    bankCode: 'Código do Banco',
    bet: 'Aposta',
    betMembers: 'Membros Apostadores',
    betRecord: 'Registro de Apostas',
    betRecordDetails: 'Detalhes do Registro de Apostas',
    betTime: 'Hora da Aposta',
    bind: 'Vincular',
    bindAccountList: 'Lista de Contas Vinculadas',
    bindBankCardList: 'Lista de Cartões Bancários Vinculados',
    bindCryptoList: 'Lista de Criptomoedas Vinculadas',
    binded: 'Vinculado',
    bindEWalletList: 'Lista de E-Wallets Vinculadas',
    bindSecurityQn: 'Configurar Pergunta de Segurança',
    bindWithdrawPw: 'Configurar Senha de Retirada',
    bonus: 'Bônus',
    bulk_read: 'Leitura em Massa',
    bulk_delete: 'Exclusão em Massa',
    cancel: 'Cancelar',
    cardAccount: 'Conta do Cartão',
    cardAddress: 'Endereço do Cartão',
    cardNumber: 'Número do Cartão',
    changeWithdrawPw: 'Alterar Senha de Retirada',
    clearingSum: 'Total de Liquidação',
    commission: 'Comissão',
    gamecommission: 'Comissão da Plataforma',
    commissionBalance: 'Saldo de Comissão',
    commissionPercent: 'Percentual de Comissão',
    commissionRate: 'Taxa de Comissão',
    commissionReport: 'Relatório de Comissão',
    commissionTransfer: 'Transferência de Comissão',
    commissionWallet: 'Carteira de Comissão',
    companyProfit: 'Lucro da Empresa',
    confirm: 'Confirmar',
    confirmNewPassword: 'Confirmar Nova Senha',
    confirmWithdraw: 'Confirmar Retirada',
    copy: 'Copiar',
    createAffiliate: 'Criar Afiliado',
    createTime: 'Hora de Criação',
    creditFlow: 'Fluxo de Crédito',
    creditFlowAmount: 'Valor do Fluxo de Crédito',
    creditFlowId: 'ID',
    creditFlowType: 'Tipo de Fluxo de Crédito',
    creditFlowDate: 'Data do Fluxo de Crédito',
    creditFlowBalance: 'Saldo do Fluxo de Crédito',
    crypto: 'Cripto',
    currentPassword: 'Senha Atual',
    deduct: 'Deduzir',
    deposit: 'Depositar',
    depositAmount: 'Valor do Depósito',
    depositBettingAmount: 'Valor do Depósito/Aposta',
    depositCount: 'Contagem de Depósitos',
    depositDate: 'Data do Depósito',
    depositRecord: 'Registro de Depósito',
    depositUsers: 'Usuários que Depositam',
    depositWallet: 'Carteira de Depósito',
    domainAffiliate: 'Domínio de Afiliado',
    domainApp: 'Domínio do PC',
    domainWeb: 'Domínio Móvel',
    downlineAffiliate: 'Afilhado Abaixo',
    downlineCommission: 'Comissão Abaixo',
    downlineCommissionRate: 'Taxa de Comissão Abaixo',
    downlineMember: 'Membro Abaixo',
    downlineProfit: 'Lucro Abaixo',
    download: 'Baixar',
    edit: 'Editar',
    editAffiliate: 'Editar Afiliado',
    editRealName: 'Atualizar Nome Real',
    email: 'E-mail',
    endDate: 'Data de Término',
    enquire: 'Consultar',
    enterTheWithdrawalAmount: 'Insira o valor da retirada',
    estimatedAffiliateCommission: 'Comissão Estimada do Afiliado',
    estimatedMemberCommission: 'Comissão Estimada do Membro',
    ewallet: 'e-Wallet',
    eventClickCount: 'Cliques na Página',
    finalSum: 'Soma Final',
    finishDate: 'Data de Conclusão',
    firstDepositAmount: 'Valor do Primeiro Depósito',
    firstDepositUsers: 'Usuários do Primeiro Depósito',
    ftd: 'Primeiro Depósito',
    gameName: 'Nome do Jogo',
    gameType: 'Tipo de Jogo',
    lastLoginTime: 'Último Tempo de Login',
    lastMonth: 'Último Mês',
    lastMonthTotal: 'Total do Último Mês',
    lastWeek: 'Última Semana',
    link: 'Link de Convite',
    loginName: 'Nome de Login',
    loginPassword: 'Senha',
    member: 'Membro',
    memberBetRecords: 'Registros de Apostas do Membro',
    memberCommission: 'Comissão do Membro',
    memberProfitDownlineProfitUnsettleCommission:
      'Lucro do Membro / Lucro Abaixo / Comissão Não Liquidada',
    memberInfo: 'Informações do Membro',
    memberType: 'Tipo de Membro',
    minActiveMemberCount: 'Contagem Mínima de Membros Ativos',
    month: 'Mês',
    monthlyAffiliateCommission: 'Comissão Mensal do Afiliado',
    monthBeforeLastTotal: 'Total do Mês Anterior ao Último',
    monthlyMemberCommission: 'Comissão Mensal do Membro',
    newMemberCount: 'Contagem de Novos Membros',
    newMember: 'Novo Membro Registrado',
    activeMemberCount: 'Contagem de Membros Ativos',
    totalRegisterCount: 'Contagem Total de Registros',
    myAccount: 'Minha Conta',
    realName: 'Nome Real',
    name: 'Nome',
    netProfit: 'Lucro Líquido',
    newPassword: 'Nova Senha',
    newUsers: 'Novos Usuários',
    ngr: 'NGR',
    noData: 'Sem Dados',
    actions: 'Ações',
    operate: 'Operar',
    operationalData: 'Dados Operacionais',
    password: 'Senha',
    paymentFee: 'Taxa de Pagamento',
    paymentMethod: 'Método de Pagamento',
    paymentName: 'Nome do Pagamento',
    payout: 'Pagamento',
    personal: 'Pessoal',
    personalInfo: 'Informações Pessoais',
    privilegeSerialNumber: 'Número de Série',
    privilegeRecord: 'Registros de Privilégios',
    privilegeName: 'Nome do Privilégio',
    platform: 'Plataforma',
    platformFee: 'Taxa da Plataforma',
    profit: 'Lucro',
    questionOne: 'Pergunta Um',
    questionTwo: 'Pergunta Dois',
    questionThree: 'Pergunta Três',
    readed: 'Lido',
    rebate: 'Reembolso',
    recordTime: 'Hora do Registro',
    reenterPassword: 'Reinserir Senha',
    referralCode: 'Código de Referência',
    referralLink: 'Link de Referência',
    regenerate: 'Regenerar',
    registerTime: 'Hora do Registro',
    reset: 'Redefinir',
    result: 'Resultado',
    revenueShare: 'Participação nos Lucros',
    revenueShareRate: 'Taxa de Participação nos Lucros',
    rollover: 'Rollover',
    search: 'Pesquisar',
    secondLevelAffiliateCommission: 'Comissão de Afiliado de Segundo Nível',
    securityInfo: 'Informações de Segurança',
    securityQuestion: 'Pergunta de Segurança',
    select: 'Selecionar',
    selectACard: 'Selecionar um cartão',
    selectBank: 'Selecionar um banco',
    selectBankCard: 'Selecionar cartão bancário',
    selectUsdtWallet: 'Por favor, selecione uma carteira USDT',
    serialNumber: 'Número de Série',
    paymentType: 'Tipo de Pagamento',
    settleTime: 'Hora de Liquidação',
    settleView: 'Visualizar',
    settlePay: 'Pagar',
    settleEdit: 'Editar',
    site: 'Site',
    startDate: 'Data de Início',
    status: 'Status',
    subtotal: 'Subtotal',
    systemAlert: 'Alerta do Sistema',
    affiliateSuccessSubmit:
      'Caro parceiro, suas informações foram enviadas com sucesso. Nosso agente informará você sobre o resultado da revisão dentro de 24 horas. Se você tiver alguma dúvida, entre em contato com nosso agente ou com o serviço de atendimento ao cliente online. Obrigado.',
    systemAnnouncement: 'Anúncio do Sistema',
    telephone: 'Telefone',
    thirdLevelAffiliateCommission: 'Comissão de Afiliado de Terceiro Nível',
    thisYear: 'Este Ano',
    thisThreeMonths: 'Três Meses',
    thisMonth: 'Este Mês',
    thisWeek: 'Esta Semana',
    title: 'Título',
    today: 'Hoje',
    totalBet: 'Aposta Total',
    totalCompanyProfit: 'Lucro Total da Empresa',
    totalCommissionProfit: 'Lucro Total da Comissão',
    totalCommission: 'Comissão Total',
    totalDeposit: 'Total do Depósito',
    totalDownlineAffiliate: 'Total de Afiliados de Baixa',
    totalDownlineMember: 'Total de Membros de Baixa',
    totalPayout: 'Pagamento Total',
    totalTransfer: 'Transferência Total',
    totalWithdraw: 'Total de Saques',
    transactionId: 'ID da Transação',
    transfer: 'Transferir',
    transferAmount: 'Montante da Transferência',
    transferDate: 'Data da Transferência',
    transferIn: 'Transferência Recebida',
    transferOut: 'Transferência Enviada',
    transferRecord: 'Registro de Transferência',
    transferType: 'Tipo de Transferência',
    transferUsers: 'Usuários de Transferência',
    unread: 'Não Lido',
    upperName: 'Nome Superior',
    usdtWallet: 'Carteira USDT',
    usdtWalletAddress: 'Endereço da Carteira USDT',
    unsettleCommission: 'Comissão Não Liquidada',
    updateBy: 'Atualizado Por',
    viewDetails: 'Ver Detalhes',
    vipLevel: 'Nível VIP',
    visitsNo: 'Número de Visitas',
    winLoss: 'Vitória/Derrota',
    withdraw: 'Sacar',
    withdrawAmount: 'Montante do Saque',
    withdrawCount: 'Contagem de Saques',
    withdrawPassword: 'Senha de Saque',
    withdrawPayoutBonus: 'Ajustar Bônus de Saque',
    withdrawalAmount: 'Montante do Saque',
    yesterday: 'Ontem',
    memberTag: 'Tag do Membro',
    memberTagDescription:
      'Por favor, insira a descrição dentro de 10 caracteres',
    tagSetting: 'Configuração de Tags',
    createTag: 'Criar Tag',
    editTag: 'Editar Tag',
    memberAmount: 'Quantidade de Membros',
    delete: 'Excluir',
    sequence: 'S/N',
    back: 'Voltar',
    all: 'Todos',
    yes: 'Sim',
    no: 'Não',
    more: 'Mais',
    remark: 'Observação do Membro',
    bonusRecord: 'Registro de Bônus',
    ftdTime: 'Hora do Primeiro Depósito',
    validBet: 'Aposta Válida',
    unAssigned: 'Não Atribuído',
    batchEditTag: 'Edição em Lote de Tags',
    personalReport: 'Relatório Pessoal',
    platformFeePercentage: 'Porcentagem da Taxa',
    rolloverAmount: 'Rollover',
    privilegeType: 'Tipo de Privilégio',
    personalCommission: 'Comissão Pessoal',
    payTime: 'Hora de Pagamento',
    payStatus: 'Status do Pagamento',
    accountAdjustment: 'Ajuste de Conta',
    commissionAdjustment: 'Ajuste de Comissão',
    image: 'Imagem',
    imageTitle: 'Título da Imagem',
    imageType: 'Tipo de Imagem',
    uploadTime: 'Hora de Upload',
    imageSize: 'Tamanho da Imagem',
    downloadTime: 'Número de Downloads',
    generateQR: 'Gerar Código QR',
    qrCode: 'Código QR',
    textInfo: 'Informações de Texto',
    qrCodeColor: 'Cor do Código QR',
    qrCodeBgColor: 'Cor de Fundo do Código QR',
    qrCodeLink: 'Link do Código QR',
    qrCodePreview: 'Pré-visualização do Código QR',
    text: 'Texto',
    textColor: 'Cor do Texto',
    textBgColor: 'Cor de Fundo do Texto',
    textPreview: 'Pré-visualização do Texto',
    preview: 'Pré-visualizar',
    custom: 'Personalizado',
    packHistory: 'Histórico de Pacotes',
    packType: 'Tipo de Pacote',
    selectSystem: 'Selecionar Sistema',
    appVersion: 'Versão do App',
    appName: 'Nome do App',
    appIcon: 'Ícone do App',
    packNow: 'Empacotar Agora',
    upload: 'Fazer Upload',
    packDate: 'Data do Pacote',
    osType: 'Tipo de Sistema',
    buildStatus: 'Status da Construção',
    apkType: 'Tipo de APK',
    normal: 'Normal',
    newKey: 'Antivírus',
    detail: 'Detalhe',
    packSize: 'Tamanho do Pacote',
    packDownloadUrl: 'URL de Download do Pacote',
    unchanged: 'Inalterado',
    unbind: 'Desvincular',
    depositWithdrawalProfit: 'Lucro de Depósito e Retirada',
    registerCount: 'Contagem de Registro',
    ftdCount: 'Contagem de FTD',
    ftdAmount: 'Montante de FTD',
    indBet: 'Aposta',
    indProfit: 'Lucro',
    last3Months: 'Últimos 3 Meses',
    total: 'Total',
    betAmount: 'Montante da Aposta',
    payoutAmount: 'Montante do Pagamento',
    totalMemberDepositAmount: 'Total do Montante Depositado pelos Membros',
    totalMemberDepositCount: 'Total da Contagem de Depósitos dos Membros',
    bonusAmount: 'Montante do Bônus',
    rebateAmount: 'Montante do Rebate',
    indBonusAmount: 'Montante do Bônus',
    indRebateAmount: 'Montante do Rebate',
    indAdjustAmount: 'Montante do Ajuste',
    grossProfit: 'Lucro Bruto',
    totalBetMemberCount: 'Contagem Total de Apostas dos Membros',
    totalDepositMemberCount: 'Contagem Total de Depósitos dos Membros',
    orderBy: 'Ordenar Por',
    sortType: 'Tipo de Ordenação',
    exclusiveDomain: 'Nome de Domínio Exclusivo',
    pcWay: 'PC',
    mobileWay: 'Mobile',
    forgetPassword: 'Esqueci a Senha',
    authenticator: 'Autenticador',
    createMember: 'Criar Membro',
    shareRatio: 'Proporção de Compartilhamento',
    rebateWallet: 'Carteira de Rebate',
    affiliateTotalRebate: 'Rebate de Afiliado',
    myRebate: 'Meu Rebate',
    totalWinLoss: 'Total de Vitória/Derrota',
    todayDeposit: 'Depósito de Hoje',
    todayWithdrawal: 'Retirada de Hoje',
    depositWithdrawalDiff: 'Diferença de Depósito e Retirada',
    recommenderCode: 'Código do Recomendador',
    memberShareRatio: 'Proporção de Compartilhamento do Membro',
    editShareRatio: 'Editar Proporção de Compartilhamento',
  },
  google: {
    google_auth_menu: 'Google Authenticator',
    download_install: 'Baixar o App',
    add_key: 'Adicionar Chave de Segurança',
    bind_verify: 'Vincular Google Auth',
    popup_title: 'Google Authenticator',
    popup_msg:
      'O Google Authenticator é uma ferramenta de senha dinâmica que atualiza automaticamente a cada 30 segundos. Ao realizar ações sensíveis, como transferir fundos, fazer retiradas ou ajustar as configurações de segurança no backend do proxy, você precisa inserir este código de verificação de identidade de 6 dígitos para autenticação.',
    popup_hint:
      'O autenticador de identidade deve ser usado em conjunto com a conta do proxy.',
    i_know: 'Eu Sei',
    download_way: 'Maneira de Baixar:',
    download_through_way: '1. Via Link de Download:',
    ios_download: 'Download para iOS',
    android_download: 'Download para Android',
    ios_scan_download: 'Escanear para Download no iOS',
    android_scan_download: 'Escanear para Download no Android',
    if_cannot_download_you_can:
      '2. Se você não conseguir baixá-lo, pode procurá-lo na Apple App Store.',
    or_search_in_google: ', ou procure na Google Play Store.',
    download_and_install: ' para baixar e instalar.',
    next_step: 'Próximo',
    keyin_pass: 'Digite a Senha & Próximo',
    keyin_your_password: 'Digite a Senha de Login',
    tips_installed:
      "Dica rápida: Após baixar e instalar, basta clicar em 'Próximo' para prosseguir.",
    qr_code: 'Código QR',
    secret_key: 'Chave Secreta',
    add_step: 'Passos para adicionar',
    add_step_desc:
      "Abra o Google Authenticator, clique no sinal de '+' no canto inferior direito, selecione 'Inserir chave manualmente' e insira qualquer conta para vinculá-la à chave secreta mencionada acima. (Escanear o código QR pode adicionar automaticamente.)",
    check_example: 'Ver Exemplo',
    back: 'Voltar',
    small_tips: 'Dica rápida:',
    small_tips_info_1:
      '1. Se você perder seu telefone ou desinstalar o app de autenticação, a chave pode ajudá-lo a recuperar o autenticador. Por favor, mantenha-a segura.',
    small_tips_info_2:
      '2. Para a segurança da sua conta, não marque a conta do proxy e o endereço do backend do proxy durante o processo de vinculação.',
    keyin_6_digit_google:
      'Por favor, digite o código de autenticação de 6 dígitos do Google.',
    auth_code: 'Google Authenticator',
    bind_now: 'Vincular Agora',
    please_enter_password: 'Por favor, digite sua senha',
    added_success: 'Código do Google Authenticator adicionado com sucesso',
    if_not_google_auth_then:
      'Se você não vinculou o Google Authenticator, não há necessidade de preenchê-lo.',
    google_auth_code: 'Digite o código do Google Authenticator de 6 dígitos',
  },
  forgetPassword: {
    verifyAuth: 'Autenticação',
    verifyQues: 'Pergunta de Segurança',
    resetPassword: 'Redefinir Senha',
    verify: 'Verificar',
    submit: 'Enviar',
    reset: 'Redefinir',
    answer: 'Resposta',
    messageAuth:
      'Por favor, digite o nome de login e o código de autenticação do Google',
    messageQues: 'Por favor, responda à seguinte pergunta de segurança',
    messageReset: 'Por favor, redefina sua senha',
    noSecurityQuestionSet:
      'Esta conta não configurou uma pergunta de segurança, por favor, entre em contato com o suporte ao cliente',
    resetSuccess: 'Senha redefinida com sucesso',
  },
  message: {
    adjustSuccess: 'Ajuste Bem-Sucedido',
    addSuccess: 'Adição Bem-Sucedida',
    bindBankCard: 'Por favor, vincule o cartão bancário primeiro',
    bindUsdtWallet: 'Por favor, vincule a carteira USDT primeiro',
    chineseCharacters: 'Por favor, insira caracteres chineses',
    commissionPaySuccess: 'Pagamento de Comissão Bem-Sucedido',
    confirmToAdjust:
      'Tem certeza de que deseja fazer o ajuste? Não é possível fazer outro ajuste após a confirmação.',
    confirmToPay: 'Confirma que deseja prosseguir para o pagamento?',
    deleteSuccess: 'Deletado com Sucesso',
    domainAppCopied: 'Domínio da Web copiado para a área de transferência.',
    domainWebCopied: 'Domínio do App copiado para a área de transferência.',
    editSuccess: 'Edição Bem-Sucedida',
    inputRealName: 'O nome real do membro não foi definido',
    emailFormat: 'Por favor, insira o formato de endereço de e-mail correto',
    inputChineseCharacters: 'Por favor, insira caracteres chineses',
    inputDigits: 'Por favor, insira dígitos',
    inputPassword: 'Por favor, insira a senha',
    inputPasswordAgain: 'Por favor, insira a senha novamente',
    length6To12: 'O comprimento deve ser de 6 a 12',
    lengthShouldBe: 'O comprimento deve ser ',
    passwordLength:
      'A senha não pode ter menos de 6 dígitos ou mais de 12 dígitos',
    redirectBankDeposit:
      'Você foi redirecionado para seu banco específico para prosseguir com o depósito. Uma vez que o depósito for bem-sucedido, será refletido aqui.',
    reenterPassword: 'Por favor, reintroduza a senha',
    referralLinkCopied:
      'O link de referência foi copiado para a área de transferência.',
    remaining: 'Restante',
    requiredAffiliateLevel: 'O nível de afiliado é obrigatório',
    required_signup_account: 'A conta da agência não pode estar vazia.',
    required_6_to_12: 'Deve ser composto de 6 a 12 dígitos ou letras.',
    requried_password: 'A senha de login não pode estar vazia.',
    required_captcha: 'O código de verificação não pode estar vazio.',
    required_4_digits: 'Deve ser composto por 4 dígitos.',
    required_confirm_pwd: 'A confirmação da senha não pode estar vazia.',
    required_same_with_password: 'Deve ser consistente com a senha de login.',
    requiredAmount: 'O valor é obrigatório',
    requiredAnswer: 'A resposta é obrigatória',
    requiredCardAccount: 'A conta do cartão é obrigatória',
    requiredCardAddress: 'O endereço do cartão é obrigatório',
    requiredCardNumber: 'Por favor, insira o número do cartão bancário',
    requiredCommission: 'A comissão é obrigatória',
    requiredEmail: 'O e-mail é obrigatório',
    requiredLoginName: 'O nome de login é obrigatório',
    requiredMemberType: 'Por favor, selecione o tipo de membro.',
    requiredOldPassword: 'A senha atual é obrigatória',
    requiredPassword: 'A senha é obrigatória',
    requiredRealName: 'O nome real é obrigatório',
    requiredRevenueShare: 'A divisão de receita é obrigatória',
    requiredRollover: 'O rollover é obrigatório',
    requiredTelephone: 'O telefone é obrigatório',
    requiredTransferAmount: 'O valor da transferência é obrigatório',
    requiredUsdtWallet: 'Por favor, insira a carteira USDT',
    requiredUserName: 'O nome de usuário é obrigatório',
    requiredWithdrawPassword: 'A senha de retirada é obrigatória',
    selectAQuestion: 'Por favor, selecione uma pergunta',
    selectBankCard: 'Por favor, selecione um cartão bancário',
    selectUsdtWallet: 'Por favor, selecione uma carteira USDT',
    setSecurityQn: 'Por favor, configure a pergunta de segurança',
    setSecAndPw:
      'Por favor, configure a pergunta de segurança e a senha de retirada',
    setWithdrawPassword: 'Por favor, configure a senha de retirada',
    singleLimit: 'Limite Único',
    success: 'Sucesso',
    times: 'vezes',
    twoPasswordNotMatch: 'As duas senhas não correspondem.',
    unbindConfirm: 'Você tem certeza de que deseja desvincular?',
    validateAdjustAmountRequired: 'O valor de ajuste é obrigatório.',
    validateAdjustReasonRequired: 'O motivo do ajuste é obrigatório.',
    validateAdjustTypeRequired: 'O tipo de ajuste é obrigatório.',
    validateBankCardNumber:
      'Apenas números são aceitos para o número do cartão bancário.',
    validateCommission: 'A comissão deve estar entre 0 e 1.',
    validateNumberOnly: 'Por favor, insira apenas números.',
    validateNumberMoreThanOne: 'Por favor, insira um número maior que 1.',
    validateRevenueShare: 'A divisão de receita deve estar entre 0 e 1.',
    validateUsdtWallet:
      'Apenas letras e números são aceitos para a carteira USDT.',
    withdrawalToday: 'Retirada Hoje',
    inputUSDTAmount: 'Por favor, insira o valor USDT.',
    inputAmount: 'Por favor, insira o valor.',
    selectAmount: 'Por favor, selecione o valor.',
    minDepositeAmount: 'Depósito mínimo',
    maxDepositeAmount: 'Depósito máximo',
    currencyRates: 'Taxas de Câmbio',
    understand: 'Entender',
    DepositCompleted: 'Concluído',
    depositNotification1:
      'Você será redirecionado para a página do seu banco para completar o depósito.',
    depositNotification2:
      'Se bem-sucedido, você receberá uma notificação nesta página.',
    validateTagDescriptionRequired: 'A descrição da tag é obrigatória.',
    confirmDelete:
      'Confirme que deseja excluir esses dados, a operação não pode ser desfeita.',
    remarkMessage:
      'Por favor, insira a observação do membro com até 200 caracteres.',
    validateTagDescriptionLength:
      'A descrição da tag deve ter entre 1 e 10 caracteres.',
    packRequestInQueue: 'Seu pedido de canal de pacote está na fila.',
    channelPackCancelSuccess: 'Seu pacote de canal foi cancelado com sucesso.',
    channelPackUrlCopied:
      'A URL do pacote de canal foi copiada para a área de transferência.',
    validateAppNameRequired: 'O nome do aplicativo é obrigatório.',
    validateAppIconRequired: 'O ícone do aplicativo é obrigatório.',
    validateShareRatioFormat:
      'A proporção de compartilhamento deve estar entre 0 e 1.',
    depositShouldBetween: 'O valor do depósito deve estar entre ',
    requiredPositiveInteger: 'Inteiro positivo é obrigatório.',
  },
  menu: {
    undefined: '',
    Dashboard: 'Painel de Controle',
    'Downline Info': 'Informações da Linha de Baixo',
    Member: 'Membro',
    Affiliate: 'Afiliado',
    AffiliateSummary: 'Resumo do Afiliado',
    gameRecord: 'Registro de Jogo',
    financeCentre: 'Centro Financeiro',
    'Bet Record': 'Registro de Apostas do Membro',
    'Deposit Record': 'Registro de Depósitos do Membro',
    Statistics: 'Estatísticas',
    'Game Stats': 'Estatísticas do Jogo',
    'Affiliate Daily Report': 'Relatório Diário do Afiliado',
    'Affiliate Center': 'Centro do Afiliado',
    Deposit: 'Depositar',
    'Bind Bank Cards': 'Vincular Cartões Bancários',
    'Bank Withdrawal': 'Retirada Bancária',
    Transfer: 'Transferir',
    'Referral Centre': 'Centro de Referências',
    'Referral Management': 'Gerenciamento de Referências',
    'Referral Link': 'Link de Referência',
    'Referral Material': 'Material de Referência',
    'Affiliate Domain': 'Domínio do Afiliado',
    'Personal Center': 'Centro Pessoal',
    'Settlement Center': 'Centro de Liquidação',
    'Finance Report': 'Relatório Financeiro',
    'Settlement Report': 'Relatório de Liquidação',
    'Channel Pack': 'Pacote de Canal',
    contactUs: 'Contate-Nos',
    'Daily Detail': 'Detalhe Diário',
    'Daily Summary': 'Resumo Diário',
    Withdraw: 'Retirar',
    'Rebate Report': 'Relatório de Reembolso',
    AffiliateOwnSummary: 'Resumo do Afiliado',
  },
  posterType: {
    OVERALL: 'Referência Geral',
    APP: 'Referência do APP',
    SPONSOR: 'Referência do Patrocinador',
    GIFT: 'Referência de Presente',
    COMPETITION: 'Referência de Competição',
    EVENT: 'Referência de Evento',
    CRYPTO: 'Referência de Cripto',
    AFFILIATE: 'Benefício do Afiliado',
  },
  packStatus: {
    IN_QUEUE: 'Na Fila',
    IN_PROGRESS: 'Em Andamento',
    SUCCESS: 'Sucesso',
    FAIL: 'Falha',
    CANCEL: 'Cancelar',
  },
  appType: {
    ALL_SITE: 'TODOS OS SITES',
    SPORT: 'ESPORTE',
    ESPORT: 'ESPORT',
  },
  member: {
    status: {
      normal: 'NORMAL',
      frozen: 'FROZEN',
    },
  },
  affiliate: {
    level: {
      JUNIOR_AFFILIATE: 'Afiliado Júnior',
      SUB_AFFILIATE: 'Subafiliado',
      AFFILIATE: 'Afiliado',
      SUPER_AFFILIATE: 'Super Afiliado',
      MASTER_AFFILIATE: 'Afiliado Master',
      CHIEF_AFFILIATE: 'Afiliado Chefe',
    },
    status: {
      APPLY: 'Aplicar',
      NORMAL: 'Normal',
      DISABLE: 'Desativar',
    },
  },
  osType: {
    ANDROID: 'ANDROID',
    IOS: 'IOS',
  },
  affiliateShareRatio: {
    AGENT_SHARE_BET_RATIO_FISH: 'Relação de Apostas-PESCA',
    AGENT_SHARE_BET_RATIO_SLOT: 'Relação de Apostas-MÁQUINA',
    AGENT_SHARE_BET_RATIO_ESPORT: 'Relação de Apostas-ESPORT',
    AGENT_SHARE_BET_RATIO_LIVE: 'Relação de Apostas-AO VIVO',
    AGENT_SHARE_BET_RATIO_SPORT: 'Relação de Apostas-ESPORTE',
    AGENT_SHARE_PROFIT_RATIO_FISH: 'Relação de Lucros-PESCA',
    AGENT_SHARE_PROFIT_RATIO_SLOT: 'Relação de Lucros-MÁQUINA',
    AGENT_SHARE_PROFIT_RATIO_ESPORT: 'Relação de Lucros-ESPORT',
    AGENT_SHARE_PROFIT_RATIO_LIVE: 'Relação de Lucros-AO VIVO',
    AGENT_SHARE_PROFIT_RATIO_SPORT: 'Relação de Lucros-ESPORTE',
  },
  error: {
    400: 'Requisição Inválida',
    403: 'Proibido',
    405: 'Método Não Permitido',
    500: 'Erro do Sistema',
    501: 'ID de Requisição Duplicado',
    502: 'Servidor Não Encontrado',
    504: 'Requisição Muito Frequente',
    601: 'Erro de Verificação de Token',
    602: 'Token expirou',
    603: 'Token está ausente',
    604: 'Conta já está logada',
    707: 'Saldo Insuficiente',
    709: 'Saldo de comissão insuficiente',
    800: 'Erro de verificação de Captcha',
    801: 'Código Captcha expirou',
    901: 'Registro de afiliado não existe',
    902: 'Membro não é um afiliado',
    903: 'Conta é um afiliado',
    904: 'Conta é um membro',
    905: 'Nome real não foi definido',
    1000: 'Nome de login ou senha estão incorretos',
    1001: 'Conta de login está desativada',
    1100: 'Nova senha não pode ser igual à senha antiga',
    1101: 'Senha antiga incorreta',
    1102: 'Senha de retirada está incorreta',
    1201: 'Resposta está incorreta',
    1308: 'Saldo não é suficiente',
    1311: 'Não envie o mesmo valor de retirada dentro de 24 horas',
    13000: 'Nome de domínio não existe',
    14000: 'Nome de login duplicado: ',
    14001: 'Número de telefone duplicado: ',
    14002: 'E-mail duplicado: ',
    14003: 'Código de afiliado não existe: ',
    14004: 'Código de afiliado não pertence a este site: ',
    14005: 'Comissão de afiliado deve ser menor que a comissão do afiliado superior: ',
    14006: 'Receita de afiliado deve ser menor que a receita do afiliado superior: ',
    14007: 'Solicitação de afiliado ainda não foi aprovada',
    14008: 'Comissão de afiliado deve ser maior que a comissão do afiliado filho: ',
    14009: 'Receita de afiliado deve ser maior que a receita do afiliado filho: ',
    14100: 'Este membro não é seu membro descendente',
    14102: 'Tag de membro já existe',
    14103: 'Limite de tag de membro excedido',
    15201: 'Senha antiga incorreta',
    15202: 'Nova senha não pode ser igual à sua senha antiga',
    14106: 'Senha incorreta',
    24000: 'Verificação Falhou',
    1009: 'Código de autenticação do Google incorreto',
    900: 'Conta não existe',
    11011: 'Proporção de compartilhamento superior não definida',
    11012: 'Proporção de compartilhamento superior não definida para o tipo de jogo: ',
    11013: 'Proporção de compartilhamento de afiliado não pode exceder a proporção superior',
    11014: 'A proporção total de compartilhamento de afiliado para todos os descendentes não pode exceder a proporção de compartilhamento do afiliado superior',
    11015: 'Proporção de compartilhamento de afiliado não definida',
    11016: 'A proporção de compartilhamento de membro não pode exceder a proporção de compartilhamento de afiliado',
    14012: 'Só é possível criar afiliado com nível inferior',
    14013: 'Afiliado tem descendentes, não pode ser definido para nível inferior',
  },
}
